<template>
  <v-app style="background-color: transparent">
    <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" />

    <v-row class="visitors-container">
      <v-col :cols="12" :md="7">
        <EventCard
          :entrance="entranceVisit"
          :exit="exitVisit"
          :showDepartment="showDepartment"
          :temperatureRange="settings.temperature_range?.split(',') ?? []"
          :temperatureRise="settings.temperature_rise"
          @unblock="onUnblockUser"
          @guestReg="onGuestReg"
        />
      </v-col>

      <v-col :cols="12" :md="5">
        <v-card elevation="2" class="settings-page">
          <v-tabs v-model="tabActive" show-arrows>
            <v-tab>{{ $ml.get("users.passages") }}</v-tab>
            <v-tab>{{ $ml.get("users.indoors") }}</v-tab>
            <v-tab>{{ $ml.get("users.alarm") }}</v-tab>
          </v-tabs>

          <MonitoringFilter
            class="pt-5 pl-3 pr-3"
            :typeUser="typeUser"
            :zoneId="zoneId"
            @change="onChangeFilter"
            :isDisabled="
              isPendingVisitors || isPendingIndoors || isPendingPasses
            "
          />

          <v-card-text>
            <v-tabs-items
              v-model="tabActive"
              style="overflow: initial"
              touchless
            >
              <v-tab-item :transition="false" :reverse-transition="false">
                <VisitorTable
                  :data="visitors.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                  :isLoading="isPendingVisitors"
                />
              </v-tab-item>

              <v-tab-item :transition="false" :reverse-transition="false">
                <IndoorTable
                  :data="indoors.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @closeVisits="initData"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                  @report="reportIndoors"
                  :isLoading="isPendingIndoors"
                >
                  <template v-slot:footer>
                    <vue-pagination
                      :pagination="indoors.pagination"
                      :offset="1"
                      @paginate="initData"
                      :isPending="isPendingIndoors"
                    />
                  </template>
                </IndoorTable>
              </v-tab-item>

              <v-tab-item :transition="false" :reverse-transition="false">
                <EventLogTable
                  :data="eventLogs.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @guestReg="onGuestReg"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                  :isLoading="isPendingPasses"
                >
                  <template v-slot:footer>
                    <div class="row">
                      <div class="col-sm-10">
                        <vue-pagination
                          :pagination="eventLogs.pagination"
                          :offset="2"
                          :isSetUrlQuery="false"
                          @paginate="initData"
                          :isPending="isPendingPasses"
                        ></vue-pagination>
                      </div>
                    </div>
                  </template>
                </EventLogTable>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="visitors-container-footer">
        <v-switch
          v-if="settings.temperature_enabled"
          v-model="temperatureCheck"
          :label="$ml.get('users.displayWithTemperature')"
          @change="onChangeTemperatureMode"
        />
      </v-col>
      <v-col cols="auto" class="visitors-container-footer">
        <v-switch
          v-model="showDepartment"
          :label="$ml.get('monitoring.showDepartment')"
          @change="onChangeShowDepartment"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-if="isShowAlarmDialog"
        :value="true"
        max-width="700px"
        persistent
      >
        <ModalAlarmDialog :data="deviceAlarm" @close="closeAlarmDialog" />
      </v-dialog>
    </v-row>

    <DeviceMonitoring />
  </v-app>
</template>

<script>
import EventCard from "./EventCard";
import MonitoringFilter from "./Filter";
import VisitorTable from "./VisitorTable";
import IndoorTable from "./IndoorTable";
import EventLogTable from "./EventLogTable";
import VuePagination from "@/components/pagination";
import ZoomImg from "@/components/ZoomImg";
import ModalAlarmDialog from "./ModalAlarmDialog";
import DeviceMonitoring from "./devices";
import { mapState } from "vuex";

const NEW_VISIT_EVENT = "newvisit";
const BEFORE_VISIT_EVENT = "beforevisit";
const ERROR_VISIT_EVENT = "errorvisit";
const ALARM_VISIT_EVENT = "alarmvisit";
const EVENT_VISIT_EVENT = "eventvisit";

const NEW_VISIT_TAB = 0;
const INDOOR_TAB = 1;
const EVENT_LOG_TAB = 2;

export default {
  components: {
    EventCard,
    MonitoringFilter,
    VisitorTable,
    IndoorTable,
    EventLogTable,
    VuePagination,
    ZoomImg,
    ModalAlarmDialog,
    DeviceMonitoring,
  },

  data() {
    return {
      isShowAlarmDialog: false,
      deviceAlarm: null,
      isPendingVisitors: false,
      isPendingIndoors: false,
      isPendingPasses: false,

      showDepartment: false,

      isOpenZoomImg: false,
      zoomImgUrl: "",
      entranceVisit: null,
      exitVisit: null,
      tabActive: EVENT_LOG_TAB,
      temperatureCheck: false,
      typeUser: null,
      zoneId: null,
      indoorsInterval: undefined,
      visitors: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },
      indoors: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },
      eventLogs: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },

      eventCounter: 0,
    };
  },

  watch: {
    tabActive(tab) {
      localStorage.setItem("terminal.dascboard.tabs", tab);
      this.resetPage();
      this.initData();
    },
  },

  methods: {
    onChangeShowDepartment(newValue) {
      localStorage.setItem("showDepartment", newValue);
    },

    openZoomImg(img) {
      this.isOpenZoomImg = true;
      this.zoomImgUrl = img;
    },

    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },

    alertNotify(visit) {
      if (visit.Blocked) {
        this.$notify({
          type: "error",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassBlockUser"),
          duration: -1,
        });
      }

      if (visit.blacklisted) {
        this.$notify({
          type: "warn",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassBlacklistUser"),
          duration: -1,
        });
      }

      if (visit.event?.temperature_status === "excess") {
        this.$notify({
          type: "error",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassTemperatureUser"),
          duration: -1,
        });
      }
    },

    onNewVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      const isZonesFiltered =
        !this.zoneId || correctVisit.zone_id == this.zoneId;

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));

      const isTypeFiltered =
        !this.typeUser || correctVisit.type_user == this.typeUser;

      if (isZonesFiltered && isZonesIncludeUser && isTypeFiltered) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToVisitorsList(correctVisit);
        this.setItemToLogList(correctVisit);

        if (correctVisit.code == 304) {
          this.showAlarmDialog(correctVisit);
        }

        this.alertNotify(correctVisit);
      }
    },

    onBeforeVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      const isZonesFiltered =
        !this.zoneId || correctVisit.zone_id == this.zoneId;

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));
      const isTypeFiltered =
        !this.typeUser || correctVisit.type_user == this.typeUser;

      if (isZonesFiltered && isZonesIncludeUser && isTypeFiltered) {
        this.setEntranceAndExitVisits(correctVisit);
        this.temperatureAlarm(correctVisit);
        this.setItemToLogList(correctVisit);
      }
    },

    onErrorVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      const isZonesFiltered =
        !this.zoneId || correctVisit.zone_id == this.zoneId;

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));

      const isTypeFiltered =
        !this.typeUser || correctVisit.type_user == this.typeUser;

      if (isZonesFiltered && isZonesIncludeUser && isTypeFiltered) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToLogList(correctVisit);

        this.alertNotify(correctVisit);
      }
    },

    onAlarmVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      const isZonesFiltered =
        !this.zoneId || correctVisit.zone_id == this.zoneId;

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));

      const isTypeFiltered =
        !this.typeUser || correctVisit.type_user == this.typeUser;

      if (isZonesFiltered && isZonesIncludeUser && isTypeFiltered) {
        this.setEntranceAndExitVisits(correctVisit);
        this.temperatureAlarm(correctVisit);
        this.setItemToLogList(correctVisit);

        if (correctVisit.code == 304) {
          this.showAlarmDialog(correctVisit);
        }

        this.alertNotify(correctVisit);
      }
    },

    onEventVisit(visit) {
      let correctVisit = this.correctVisits(visit, false);

      if (visit.event?.code === "350") {
        correctVisit = this.correctEventVisits(visit);
      }

      if (
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToLogList(correctVisit);

        if (correctVisit.code == 304) {
          this.showAlarmDialog(correctVisit);
        }

        this.alertNotify(correctVisit);
      }
    },

    connectWebSockets() {
      this.$root.$on(NEW_VISIT_EVENT, (visit) => {
        this.onNewVisit(visit);
      });

      this.$root.$on(BEFORE_VISIT_EVENT, (visit) => {
        this.onBeforeVisit(visit);
      });

      this.$root.$on(ERROR_VISIT_EVENT, (visit) => {
        this.onErrorVisit(visit);
      });

      this.$root.$on(ALARM_VISIT_EVENT, (visit) => {
        this.onAlarmVisit(visit);
      });

      this.$root.$on(EVENT_VISIT_EVENT, (visit) => {
        this.onEventVisit(visit);
      });
    },

    disconnectedWebSockets() {
      this.$root.$off(NEW_VISIT_EVENT);
      this.$root.$off(BEFORE_VISIT_EVENT);
      this.$root.$off(ERROR_VISIT_EVENT);
      this.$root.$off(ALARM_VISIT_EVENT);
      this.$root.$off(EVENT_VISIT_EVENT);
    },

    correctVisits(visit, is_short) {
      const item = visit;

      let format = is_short ? "HH:mm:ss" : "DD.MM.YY HH:mm:ss";
      item.visits = this.$moment(String(item.created_at)).format(format);

      let zone = "";
      let zone_id = 0;
      let data = {};
      let fullname = "";
      let udata = {};
      let device = "";
      let identity_types = [];
      let identity_value = "";
      let message = "";
      let code = "";
      let pass_type = "";
      if (item.event) {
        data = item.event;
        zone = data.zone || "";
        fullname = data.name || this.$ml.get("users.unrecognized");
        device = data.device_name || "";
        identity_value = data.identity_value || "";
        message = data.message || "";
        code = data.code || "";
        zone_id = data.zone_id || "";
        identity_types = this.getIdentityTypesArr(data.identity_types);
        if (data.type) {
          pass_type = data.type;
        } else {
          pass_type = item.user_id ? "before" : "alarm";
        }
      }
      if (!item.full_name || !item.full_name.trim()) {
        item.full_name = fullname;
      }

      if (item.udata) {
        udata = JSON.parse(item.udata);
      }
      if (data.temperature > 0) {
        item.temperatureValue = data.temperature;
        if (!this.settings.temperature_rise)
          item.temperature =
            "/ " +
            this.calculateCalcFeel(data.temperature) +
            "°" +
            this.settings.temp_meas.toUpperCase();
      }

      item.blocked = visit.Blocked;
      item.zone_id = zone_id;
      item.identity_value = identity_value;
      item.message = message;
      item.code = code;
      item.identity_types = identity_types;
      item.pass_type = pass_type;
      item.company = "";
      let cmpname = [];
      item.organization = item.organization || [];
      item.organization.forEach((el) => {
        cmpname.push(el.name);
      });

      item.company = cmpname.join(", ");
      cmpname = null;

      if (parseInt(data.code) >= 301) {
        item.eventType = "deviceAlarm";
      } else {
        item.eventType = "userAlarm";
      }

      if (data["image_encode"]) {
        item.photo = "data:image/png;base64," + data["image_encode"];
        item.avatar = item.photo;
      } else {
        if (item.eventType === "deviceAlarm") {
          if (item.photo) {
            item.photo = item.photo ? item.photo : "avatar.png";
            item.avatar = "/avatar/small/" + item.photo;
            item.photo = "/avatar/profile/" + item.photo;
          } else {
            item.photo = require("@/assets/img/error.svg");
            item.avatar = require("@/assets/img/error.svg");
          }
        } else {
          item.photo = item.photo ? item.photo : "avatar.png";
          item.avatar = "/avatar/small/" + item.photo;
          item.photo = "/avatar/profile/" + item.photo;
        }
      }

      item.isHasPhoto = item.photo ? true : false;

      item.udata = "";

      if (item.type_user) {
        item.utype = this.$ml.get("users." + item.type_user);
      }

      if (!item.purpose) item.purpose = "exit";

      item.purposeType = item.purpose;

      if (zone) item.purpose = `${zone} / ${device}`;
      item.data = "";

      return item;
    },

    correctEventVisits(visit) {
      const code = visit.event?.code;

      const data = {
        full_name: this.$ml.get("monitoring.otherEvent"),
        zone: visit.event?.zone || "",
        device: visit.event?.device_name || "",
        purposeType: "",
        purpose: "",
        photo: "/avatar/profile/avatar.png",
        avatar: "/avatar/small/avatar.png",
        eventType: "otherEvent",
        visits: this.$moment(String(visit.created_at)).format(
          "DD.MM.YY HH:mm:ss"
        ),
        created_at: visit.created_at,
        blocked: visit.Blocked,
      };

      data.purposeType = visit.purposeType || visit.purpose || "exit";

      if (data.zone) data.purpose = `${data.zone} / ${data.device}`;

      switch (code) {
        case "350":
          data.full_name = this.$ml.get("monitoring.pressBtn");
          data.photo = require("@/assets/img/press-btn.svg");
          data.avatar = require("@/assets/img/press-btn.svg");
          break;
        default:
          break;
      }

      return data;
    },

    calculateCalcFeel(t) {
      if (this.settings.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    getIdentityTypesArr(types) {
      let result = [];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    setEntranceAndExitVisits(visit) {
      if (visit) {
        this.eventCounter = this.eventCounter + 1;

        switch (visit.purposeType) {
          case "entrance":
          case "duplex":
          case "camera":
          case "basiclock":
            if (
              !this.entranceVisit ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.entranceVisit.created_at)
              )
            ) {
              this.entranceVisit = { ...visit };
            }
            break;
          case "exit":
            if (
              !this.exitVisit ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.exitVisit.created_at)
              )
            ) {
              this.exitVisit = { ...visit };
            }
            break;
          default:
            this.entranceVisit = null;
            this.exitVisit = null;
            break;
        }
      }
    },

    clearEntranceAndExitVisits() {
      this.entranceVisit = null;
      this.exitVisit = null;
    },

    getSettings() {
      const tab = parseInt(localStorage.getItem("terminal.dascboard.tabs"));

      if (tab !== null) {
        this.tabActive = tab;
      }
    },

    onUnblockUser(id) {
      if (this.entranceVisit.user_id === id) {
        this.entranceVisit.blocked = false;
      }

      if (this.exitVisit.user_id === id) {
        this.exitVisit.blocked = false;
      }
    },

    onGuestReg(user) {
      this.$router.push({ name: "adduser", params: { user } });
    },

    initData() {
      this.$timer.stop("getIndoors");

      switch (this.tabActive) {
        case NEW_VISIT_TAB:
          this.getVisitors();

          break;
        case INDOOR_TAB:
          this.getIndoors(true);

          this.$timer.start("getIndoors");
          break;
        case EVENT_LOG_TAB:
          this.getEventLog();

          break;
        default:
          this.getVisitors();

          break;
      }
    },

    setItemToVisitorsList(visit) {
      const isAddToArray =
        this.visitors.data.length &&
        this.visitors.pagination.current_page === 1 &&
        this.$moment(visit.created_at).isAfter(
          this.$moment(
            this.visitors.data[this.visitors.data.length - 1].created_at
          )
        );

      if (isAddToArray) {
        if (this.visitors.data.length > this.visitors.pagination.per_page - 1)
          this.visitors.data.splice(-1);

        for (let i = 0; i < this.visitors.data.length; i++) {
          const arrVisit = this.visitors.data[i];

          this.eventCounter = this.eventCounter + 1;

          if (
            this.$moment(visit.created_at).isAfter(
              this.$moment(arrVisit.created_at)
            )
          ) {
            this.visitors.data.splice(i, 0, visit);
            break;
          }
        }
      } else {
        if (this.visitors.data.length < this.visitors.pagination.per_page)
          this.visitors.data.push(visit);
      }
    },

    setItemToLogList(visit) {
      const isAddToArray =
        this.eventLogs.data.length &&
        this.eventLogs.pagination.current_page === 1 &&
        this.$moment(visit.created_at).isAfter(
          this.$moment(
            this.eventLogs.data[this.eventLogs.data.length - 1].created_at
          )
        );

      const isSort = this.$moment(visit.created_at).isAfter(
        this.$moment(this.eventLogs.data[0].created_at)
      );

      if (isAddToArray) {
        if (
          this.eventLogs.data.length >
          this.eventLogs.pagination.per_page - 1
        ) {
          if (this.eventLogs.pagination.total < 2) {
            this.eventLogs.pagination.total = 2;
          }

          this.eventLogs.data.splice(-1);
        }

        for (let i = 0; i < this.eventLogs.data.length; i++) {
          const arrVisit = this.eventLogs.data[i];

          this.eventCounter = this.eventCounter + 1;

          if (
            this.$moment(visit.created_at).isAfter(
              this.$moment(arrVisit.created_at)
            )
          ) {
            this.eventLogs.data.splice(i, 0, visit);
            break;
          }
        }
      } else {
        if (this.eventLogs.data.length < this.eventLogs.pagination.per_page)
          this.eventLogs.data.push(visit);
      }
    },

    getVisitors() {
      this.isPendingVisitors = true;
      let params = {
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("visitors", { params })
        .then((response) => {
          this.visitors.data = response.data.data
            ? response.data.data.map((item) => {
                return this.correctVisits(item, false);
              })
            : [];

          this.visitors.pagination = response.data.meta.pagination || {
            total: 0,
            per_page: 2,
            to: 0,
            current_page: 1,
          };
          this.isPendingVisitors = false;
        })
        .catch(() => (this.isPendingVisitors = false));
    },

    getIndoors(isInterval = false) {
      if (isInterval) {
        this.isPendingIndoors = true;
      }
      let params = {
        page: this.indoors.pagination?.current_page || 1,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("advanced/log", {
        params,
      })
        .then((response) => {
          this.indoors.data = response.data.data
            ? response.data.data.map((item) => {
                return this.correctVisits(item, false);
              })
            : [];
          this.indoors.pagination = response.data.meta.pagination || {
            total: 0,
            per_page: 2,
            to: 0,
            current_page: 1,
          };
          this.isPendingIndoors = false;
        })
        .catch(() => (this.isPendingIndoors = false));
    },

    getEventLog(isFirstRequest) {
      this.isPendingPasses = true;
      let params = {
        page: this.eventLogs.pagination?.current_page || 1,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("alarmvisits", { params })
        .then((response) => {
          this.eventLogs.data = response.data.data
            ? response.data.data.map((item) => {
                if (item.event.code === "350") {
                  return this.correctEventVisits(item);
                }
                return this.correctVisits(item, false);
              })
            : [];

          this.eventLogs.pagination = response.data.meta.pagination || {
            total: 0,
            per_page: 2,
            to: 0,
            current_page: 1,
          };

          if (isFirstRequest) {
            const visitorEntrance = this.eventLogs.data.find(
              (user) =>
                user.purposeType === "entrance" ||
                user.purposeType === "duplex" ||
                user.purposeType === "camera" ||
                user.purposeType === "basiclock"
            );
            const visitorExit = this.eventLogs.data.find(
              (user) => user.purposeType === "exit"
            );

            this.setEntranceAndExitVisits(visitorEntrance);
            this.setEntranceAndExitVisits(visitorExit);
          }
          this.isPendingPasses = false;
        })
        .catch(() => (this.isPendingPasses = false));
    },

    temperatureAlarm(visit) {
      if (this.isTempAlarm(visit.temperatureValue)) {
        const audio = new Audio(require("@/assets/sound/ambulance.mp3"));
        audio.play();

        this.$notify({
          type: "error",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassTemperatureUser"),
          duration: -1,
        });
      }
    },

    isTempAlarm(temp) {
      if (
        temp &&
        (temp < parseFloat(this.settings.temperature_range?.split(",")[0]) ||
          temp > parseFloat(this.settings.temperature_range?.split(",")[1]))
      ) {
        return true;
      }

      return false;
    },

    onChangeFilter({ typeUser, zoneId }) {
      this.typeUser = typeUser;
      this.zoneId = zoneId;
      this.resetPage();

      this.initData();
    },

    resetPage(page = 1) {
      this.visitors.pagination.current_page = page;
      this.indoors.pagination.current_page = page;
      this.eventLogs.pagination.current_page = page;
    },

    showUser(item) {
      if (item?.user_id) {
        this.$router.push({ name: "showUser", params: { id: item.user_id } });
      }
    },

    styleRow(item) {
      if (item.blacklisted) {
        return "background: #272D3D; color: #fff;";
      } else if (this.isTempAlarm(item.temperatureValue)) {
        return "background: #ff9996; color: #000;";
      }
    },

    onChangeTemperatureMode() {
      this.resetPage();
      this.initData();
    },

    reportIndoors() {
      const params = {
        lang: this.settings.lang,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params.type_user = this.typeUser;
      }

      if (this.zoneId) {
        params.zone_id = this.zoneId;
      }

      const requestParams = {
        method: "get",
        params,
        url: "report/occup",
        responseType: "blob",
      };

      this.axios
        .request(requestParams)
        .then((response) => {
          let filename =
            "occup-" + this.$moment().format("YYYYMMDDHHmmss") + ".xlsx";
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$dialog.alert(this.$ml.get("advanced.nopass"), {
            loader: false,
            okText: "OK",
            backdropClose: true,
          });
        });
    },

    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
      this.deviceAlarm = null;
    },

    showAlarmDialog(alarm) {
      this.deviceAlarm = alarm;
      this.isShowAlarmDialog = true;
    },
  },

  timers: {
    getIndoors: { time: 5000, repeat: true },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },

  destroyed() {
    this.disconnectedWebSockets();
  },

  created() {
    this.showDepartment = localStorage.getItem("showDepartment") == "true";
    this.connectWebSockets();
    this.getSettings();
    this.initData();
    this.getEventLog(true);
  },
};
</script>
