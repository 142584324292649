<template>
  <v-app>
    <div class="container add-new-visitor-form col-sm-12">
      <div class="flash-message" style="display: none"></div>
      <div class="row">
        <div class="column col-sm-4 image-box">
          <ImageBox
            v-on:toggle="togglePhoto($event)"
            :devices="devices"
            :photo="photo"
            :newphoto="newphoto"
            :feature="feature"
            :showDelete="isHasPhoto"
            @delete_photo="deletePhoto"
          />
          <CardsAccess
            v-model="cards"
            @result="cards = $event"
            style="margin-top: 40px; text-align: left; padding-left: 20px"
          />
        </div>
        <div class="column form-column col-sm-8">
          <UserAlert :message="errorMessage" :userId="parseUserIdLangMessage" />

          <div
            v-if="faceValidErrorMessage"
            class="alert alert-danger"
            role="alert"
          >
            {{ faceValidErrorMessage }}
          </div>
          <v-tabs v-model="tab" show-arrows>
            <v-tab>{{ $ml.get("user.basicData") }}</v-tab>
            <v-tab
              v-if="
                typeUser == 'manager' &&
                (isAdmin() || $auth.user().roles.includes('managercreate'))
              "
            >
              {{ $ml.get("user.accessControl") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="overflow: initial">
            <v-tab-item :transition="false" :reverse-transition="false">
              <div style="padding-top: 15px">
                <v-alert v-if="isTwin" type="info" class="mb-5">{{
                  $ml.get("user.isTwin")
                }}</v-alert>

                <v-row>
                  <v-col cols="12">
                    <FullName
                      :surname="last_name"
                      :name="first_name"
                      :patronymic="middle_name"
                      @change="onChangeName"
                    />

                    <v-text-field
                      v-model="phone"
                      :label="phoneLabel"
                      :required="phoneRequired"
                      :rules="phoneRules"
                      outlined
                    ></v-text-field>

                    <v-text-field
                      v-show="typeUser == 'employee' || typeUser == 'manager'"
                      v-model.trim="tabNum"
                      :label="$ml.get('user.tabNum')"
                      maxlength="12"
                      counter="12"
                      outlined
                    />

                    <treeselect
                      v-model="department"
                      :multiple="false"
                      :options="departments"
                      :placeholder="$ml.get('user.departments')"
                      :searchable="true"
                      :show-count="true"
                      class="mb-5"
                    />

                    <AccessList
                      :items="companies"
                      :values="accesslist"
                      @values="accesslist = $event"
                      class="mb-5"
                    />

                    <v-autocomplete
                      v-model="escort_list"
                      :loading="getPersonsStatus == 'IS_PENDING'"
                      :items="personsList"
                      :search-input.sync="searchPersons"
                      :cache-items="true"
                      :label="$ml.get('user.user_escort')"
                      :placeholder="$ml.get('message.startSearchFio')"
                      chips
                      multiple
                      item-text="full_name"
                      item-value="uid"
                      outlined
                      clearable
                      hide-no-data
                      hide-selected
                      deletable-chips
                      :menu-props="{ closeOnContentClick: true }"
                      @change="onSelectEscort"
                    />

                    <v-textarea
                      v-model="note"
                      maxlength="64000"
                      :label="$ml.get('user.note')"
                      variant="outlined"
                      auto-grow
                      rows="3"
                      outlined
                      counter
                    >
                    </v-textarea>
                  </v-col>

                  <v-col>
                    <v-switch
                      v-model="use_personal_mode"
                      :label="$ml.get('user.use_personal_mode')"
                    />
                  </v-col>
                </v-row>

                <h6>{{ $ml.get("users.userType") }}:</h6>
                <div class="clearfix radio-groups-container">
                  <template v-for="(type, i) in types">
                    <div
                      :key="i"
                      class="radio-group"
                      v-if="
                        isAdmin() ||
                        ($auth.user().roles.includes('managercreate') &&
                          type.name == 'manager') ||
                        type.name != 'manager' ||
                        typeUser == 'manager'
                      "
                    >
                      <input
                        name="typeUser"
                        type="radio"
                        :id="type.name"
                        :value="type.name"
                        v-model="typeUser"
                        :disabled="
                          !isAdmin() &&
                          !$auth.user().roles.includes('useredit') &&
                          !$auth.user().roles.includes('userdel') &&
                          !$auth.user().roles.includes('managercreate')
                        "
                      />
                      <label :for="type.name">{{ type.title }}</label>
                    </div>
                  </template>
                </div>

                <!-- Настраиваем вывод правил для конкретного типа -->

                <v-text-field
                  v-if="typeUser == 'guest'"
                  name="purpose"
                  v-model="visit_purpose"
                  :label="$ml.get('user.visit_purpose')"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="post"
                  name="post"
                  :label="$ml.get('user.post')"
                  outlined
                ></v-text-field>

                <template v-if="typeUser == 'guest' || typeUser == 'employee'">
                  <template v-if="typeUser == 'guest'">
                    <h6>{{ $ml.get("user.type_of_pass") }}:</h6>
                    <div class="clearfix radio-groups-container">
                      <div
                        v-for="(type, i) in passtypes"
                        :key="i"
                        class="radio-group"
                      >
                        <input
                          name="onepass"
                          type="radio"
                          :id="type.name"
                          :value="type.val"
                          v-model="onepass"
                        />
                        <label :for="type.name">{{ type.title }}</label>
                      </div>
                    </div>
                  </template>

                  <div style="max-width: 500px">
                    <v-row class="mb-5" align="center">
                      <v-col cols="12" class="pb-0 pt-0">
                        {{ $ml.get("requests.dueFrom") }}
                      </v-col>
                      <v-col cols="5">
                        <v-menu
                          v-model="dateStartMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateStart"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                            />
                          </template>

                          <v-date-picker
                            v-model="dateStart"
                            :min="dueFromMin"
                            @input="onChangeDueDateFrom"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateStartMenu = false"
                            >
                              {{ $ml.get("button.close") }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          type="time"
                          v-model="timeStart"
                          outlined
                          :disabled="!dateStart"
                          hide-details
                        />
                      </v-col>

                      <v-col cols="3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="resetStart"
                            >
                              <v-icon>mdi-cached</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $ml.get("button.reset") }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-row class="mb-5" align="center">
                      <v-col cols="12" class="pb-0 pt-0">
                        {{ $ml.get("requests.dueTo") }}
                      </v-col>
                      <v-col cols="5">
                        <v-menu
                          v-model="dateExpireMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateExpire"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                            />
                          </template>
                          <v-date-picker
                            v-model="dateExpire"
                            :min="dueToMin"
                            @input="dateExpireMenu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateExpireMenu = false"
                            >
                              {{ $ml.get("button.close") }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          type="time"
                          v-model="timeExpire"
                          outlined
                          :disabled="!dateExpire"
                          hide-details
                        />
                      </v-col>

                      <v-col cols="3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="resetExpire"
                            >
                              <v-icon>mdi-cached</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $ml.get("button.reset") }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                </template>

                <h6>{{ $ml.get("user.document") }}:</h6>

                <div class="clearfix radio-groups-container">
                  <div class="radio-group">
                    <input
                      name="passport"
                      type="radio"
                      id="passport"
                      value="passport"
                      v-model="document_type"
                      checked
                    />
                    <label for="passport">{{ $ml.get("user.passport") }}</label>
                  </div>
                  <div class="radio-group">
                    <input
                      name="driverLicense"
                      type="radio"
                      id="driver_license"
                      value="license"
                      v-model="document_type"
                    />
                    <label for="driver_license">{{
                      $ml.get("user.drivers_license")
                    }}</label>
                  </div>
                </div>

                <v-text-field
                  v-if="document_type"
                  name="documentNumber"
                  v-model="document_number"
                  :label="$ml.get('user.document_number')"
                  outlined
                ></v-text-field>
              </div>
            </v-tab-item>

            <v-tab-item
              v-if="
                typeUser == 'manager' &&
                (isAdmin() || $auth.user().roles.includes('managercreate'))
              "
            >
              <div style="margin-top: 15px; margin-bottom: 25px">
                <h6>{{ $ml.get("auth.cabinetLogin") }}</h6>
                <div style="max-width: 300px">
                  <v-text-field
                    :disabled="permission"
                    v-model="username"
                    :rules="emailRules"
                    :label="`${$ml.get('auth.email')} *`"
                    outlined
                    autocomplete="off"
                    required
                  />

                  <v-row v-show="!isChangePass">
                    <v-col>
                      <v-btn @click="isChangePass = true">
                        {{ $ml.get("guard.changeAuth") }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <template v-if="isChangePass">
                    <v-text-field
                      v-model="password"
                      :rules="passwordRules"
                      :label="`${$ml.get('auth.password')} *`"
                      type="password"
                      outlined
                      autocomplete="off"
                      required
                    />
                    <v-text-field
                      v-if="password"
                      ref="passwordConfirmation"
                      class="password-guard"
                      v-model="password_confirmation"
                      :rules="passwordConfirmRules"
                      :label="`${$ml.get('auth.passwordConfirm')} *`"
                      type="password"
                      outlined
                      autocomplete="off"
                      required
                    />
                  </template>
                </div>
                <hr />
                <h4>{{ $ml.get("user.groups_management") }}</h4>
                <h6>{{ $ml.get("user.departments") }} *</h6>
                <treeselect
                  v-model="departmentperm"
                  :multiple="true"
                  :options="departments"
                  :placeholder="$ml.get('user.departments')"
                  :searchable="true"
                  :show-count="true"
                  flat
                />
                <h6 style="margin-top: 20px">
                  {{ $ml.get("users.subdivision") }} *
                </h6>
                <treeselect
                  v-model="subdivisionperm"
                  :multiple="true"
                  :options="companies"
                  :placeholder="$ml.get('users.subdivision')"
                  :searchable="true"
                  :show-count="true"
                  flat
                />
              </div>
              <h4>{{ $ml.get("user.rules") }}:</h4>
              <v-switch
                v-model="requestСonfirmRule"
                :label="$ml.get('user.request_confirm')"
              />
              <v-switch
                v-model="requestEmployee"
                :label="$ml.get('permission.requestsemployee')"
              />
              <v-switch
                v-model="editDepartmentRule"
                :label="$ml.get('user.edit_department')"
              />
              <v-switch
                v-model="editUsersRule"
                :label="$ml.get('user.edit_users')"
              />
              <!--v-switch
                v-show="editUsersRule"
                v-model="managerCreateRule"
                :label="$ml.get('permission.managercreate')"
              /-->
            </v-tab-item>
          </v-tabs-items>

          <v-checkbox
            v-model="dataProcessing"
            :label="$ml.get('message.processingData')"
            class="mt-0"
          />

          <v-alert
            v-if="validFormMessage"
            type="error"
            v-html="validFormMessage"
          />

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn @click="goCancel">
                {{ $ml.get("button.back") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <template v-if="userId">
              <v-col v-if="deleted && isDelUser()" cols="auto">
                <v-btn color="success" @click.prevent="restoreUser">
                  {{ $ml.get("button.restore") }}
                </v-btn>
              </v-col>
              <v-col v-if="deleted && isClearUser()" cols="auto">
                <v-btn color="error" @click.prevent="clearUser">
                  {{ $ml.get("button.clear") }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!deleted && typeUser != '' && isDelUser()"
                cols="auto"
              >
                <v-btn color="error" @click.prevent="deleteUser">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
            </template>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="isPendingStore"
                @click.prevent="onClickSavebtn"
                :disabled="lookBtn"
              >
                {{ $ml.get("button.save") }}
              </v-btn>
            </v-col>
            <!-- <button
            v-if="!deleted && userId &&
              (isEditUser() || isAddUser())
              "
            class="green-transparent-link-button refeature"
            style="margin-left: 15px;"
            type="button"
            @click.prevent="refeature"
          >
            {{ $ml.get("button.refeature") }}
          </button> -->
          </v-row>
        </div>
        <span class="invalid-feedback" role="alert" id="image-error"></span>
      </div>
    </div>
    <div class="filler"></div>
  </v-app>
</template>

<script>
import { isEmail } from "validator";

import ImageBox from "@/components/ImageBox";
import CardsAccess from "@/components/CardsAccess";
import AccessList from "@/components/AccessList";
import UserAlert from "@/components/UserAlert";
import Treeselect from "@riophae/vue-treeselect";
import FullName from "@/components/fullname";
import faceValidate from "@/mixins/faceValidate";
import debounce from "lodash.debounce";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

export default {
  mixins: [faceValidate],

  props: {
    userId: {
      default: 0,
    },
  },

  name: "UserEdit",

  components: {
    ImageBox,
    CardsAccess,
    AccessList,
    Treeselect,
    UserAlert,
    FullName,
  },

  data() {
    const { user } = this.$route.params;

    const last_name = user?.last_name || "";
    const typeUser = user?.type_user || "employee";
    const newphoto = user?.photo_encrypted || "";
    const onepass = user?.onepass || false;
    const dateStart = user?.start_date
      ? this.$moment(user.start_date).format(dateFormat)
      : "";
    const timeStart = user?.start_date
      ? this.$moment(user.start_date).format(timeFormat)
      : "";
    const dateExpire = user?.expire_date
      ? this.$moment(user.expire_date).format(dateFormat)
      : "";
    const timeExpire = user?.expire_date
      ? this.$moment(user.expire_date).format(timeFormat)
      : "";

    const cards = [];

    if (user && user.identityType && user.identityValue) {
      cards.push({
        name: `${user.identityType}-${user.identityValue}`,
        type_card: user.identityType,
        number: user.identityValue,
      });
    }

    return {
      isPendingStore: false,
      isSuccessStore: false,
      isFailureStore: false,

      getPersonsStatus: "",
      personsList: [],
      searchPersons: null,
      escort_list: [],

      types: [
        { name: "guest", title: this.$ml.get("users.guest") },
        { name: "employee", title: this.$ml.get("users.employee") },
        { name: "manager", title: this.$ml.get("users.manager") },
      ],
      passtypes: [
        { name: "pass_one", val: true, title: this.$ml.get("user.pass_one") },
        { name: "pass_all", val: false, title: this.$ml.get("user.pass_all") },
      ],
      tab: 0,
      cards,
      deleted: false,
      typeUser,
      newphoto,
      feature: "",
      photo: "",
      phone: "",
      post: "",
      dateStart,
      dateStartMenu: false,
      timeStart,
      dateExpire,
      dateExpireMenu: false,
      timeExpire,
      first_name: "",
      middle_name: "",
      last_name,
      visit_purpose: "",
      document_type: "",
      document_number: "",
      note: "",
      blocked: false,
      blacklisted: false,
      companies: [],
      departments: [],
      departmentsFlat: [],
      subdivisionsFlat: [],
      departmentperm: null,
      subdivisionperm: null,
      department: null,
      devices: [],
      dataProcessing: this.userId > 0,
      requestСonfirmRule: false,
      requestEmployee: false,
      editUsersRule: false,
      editDepartmentRule: false,
      managerCreateRule: false,
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      emailRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => this.validateEmail(v) || this.$ml.get("user.email_char_valid"),
        (v) => v.length <= 256 || this.$ml.get("user.length_email"),
      ],
      passwordRules: [
        (v) =>
          this.validPassword(v) || this.$ml.get("auth.password_char_valid"),
        (v) =>
          (v.length >= 6 && v.length <= 256) ||
          this.$ml.get("auth.lenghtn_password"),
      ],
      passwordConfirmRules: [
        (v) =>
          !!v ||
          this.$ml.get("auth.password") +
            " " +
            this.$ml.get("message.IsRequired"),
        (v) => v == this.password || this.$ml.get("auth.passwords_must_match"),
      ],
      errorMessage: "",
      twin_id: 0,
      isTwin: false,
      onepass,
      use_personal_mode: false,
      lookBtn: false,
      accesslist: [],
      NoCheckPhoto: true,
      password_confirmation: "",
      username: "",
      password: "",
      permission: null,
      isChangePass: true,
      validFormMessage: "",
      tabNum: "",
    };
  },
  computed: {
    cardsAccess() {
      return this.cards;
    },

    phoneLabel() {
      let label = this.$ml.get("user.phone");

      if (this.typeUser === "manager") {
        label += " *";
      }

      return label;
    },

    isHasPhoto() {
      if (this.photo) {
        return true;
      } else if (this.newphoto && this.newphoto != "NONE") {
        return true;
      }
      return false;
    },

    phoneRequired() {
      let required = false;

      if (this.typeUser === "manager") {
        required = true;
      }

      return required;
    },

    phoneRules() {
      let rules = [];

      if (this.typeUser === "manager") {
        rules = [(v) => !!v || this.$ml.get("message.NameIsRequired")];
      }

      return rules;
    },

    dueFromMin() {
      const date = this.$moment().format(dateFormat);

      return date;
    },

    dueToMin() {
      const date = this.dateStart || this.dueFromMin;

      return date;
    },
  },
  methods: {
    validPassword(v) {
      var re = /^[^а-яА-Я]*$/;
      return re.test(v);
    },
    onSelectEscort() {
      this.searchPersons = null;
      this.personsList = [];
    },
    validateEmail(v) {
      return isEmail(v);
    },
    validUserName(v) {
      const re = /^[a-zA-Zа-яА-ЯёЁ]{1,}'?-?[a-zA-Zа-яА-ЯёЁ]{1,}$/;
      return re.test(v);
    },
    validUserConfirmPassword(v) {
      return (
        v == this.password_confirmation || this.password_confirmation == ""
      );
    },
    validatePermission() {
      if (
        this.typeUser != "manager" ||
        (this.typeUser == "manager" &&
          this.permission &&
          (!this.password ||
            (this.password && this.password == this.password_confirmation))) ||
        (this.typeUser == "manager" &&
          !this.permission &&
          this.username &&
          this.password &&
          this.password == this.password_confirmation)
      )
        return true;
    },
    goCancel() {
      if (this.userId) {
        this.$router.push({ path: `/user/${this.userId}` });
      } else {
        let linkBack = "/users";
        if (localStorage.getItem("terminal.target.page") !== null) {
          linkBack = localStorage.getItem("terminal.target.page");
        }
        this.$router.push({ path: linkBack });
      }
    },
    deletePhoto() {
      this.photo = null;
      this.newphoto = "NONE";
    },
    togglePhoto(val) {
      this.newphoto = val;

      this.validateFace(val);
    },
    getCompanies() {
      this.axios.get("/subdivisions/tree").then((response) => {
        if (response.status == 200) {
          this.companies = response.data.data ? response.data.data : [];
        }
      });
    },
    getDepartments() {
      this.axios.get("/departments/tree").then((response) => {
        if (response.status == 200) {
          this.departments = response.data.data ? response.data.data : [];
        }
      });
    },
    getDevices() {
      this.axios.get("/devices").then((response) => {
        let devices = response.data ? response.data : [];
        devices.data.forEach((item) => {
          if (item.device_type != "UFACE") {
            this.devices.push(item);
          }
        });
      });
    },
    restoreUser() {
      this.axios
        .put("/user/restore", { ids: [parseInt(this.userId)] })
        .then((response) => {
          if (response.status == 200) {
            this.deleted = false;
          }
        });
    },
    clearUser() {
      if (this.userId) {
        let _this = this;
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(() => {
            this.axios
              .put("/user/clear", { ids: [parseInt(_this.userId)] })
              .then((response) => {
                if (response.status == 200) {
                  this.$router.push({ path: `/users` });

                  this.$notify({
                    group: "info",
                    type: "success",
                    text: this.$ml.get("message.userClearSuccess"),
                  });
                }
              });
          });
      }
    },
    deleteUser() {
      if (this.userId) {
        let _this = this;
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(() => {
            this.axios
              .put("/user/delete", { ids: [parseInt(_this.userId)] })
              .then((response) => {
                if (response.status == 200) {
                  _this.deleted = true;

                  this.$notify({
                    group: "info",
                    type: "success",
                    text: this.$ml.get("message.userDeleteSuccess"),
                  });
                }
              });
          });
      }
    },
    isAdmin() {
      return this.$auth.user().type_user == "admin";
    },
    isAddUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null && roles.includes("useredit") && this.userId) ||
        (roles != null && roles.includes("userdel"))
      );
    },
    isEditUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null && roles.includes("useredit")) ||
        (roles != null && roles.includes("userdel"))
      );
    },
    isDelUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null &&
          roles.includes("userdel") &&
          this.typeUser != "manager")
      );
    },
    isClearUser() {
      return this.$auth.user().type_user == "admin";
    },
    async getUser() {
      if (this.userId) {
        this.axios
          .get("/user/" + this.userId)
          .then(async (response) => {
            if (response.status == 200) {
              let item = response.data.data;
              this.last_name = item.last_name;
              this.first_name = item.first_name;
              this.middle_name = item.middle_name;
              this.permission = item.permission || null;
              this.isChangePass = item.permission ? false : true;
              this.cards = item.cards;
              this.typeUser = item.type_user;
              this.photo = item.photo;
              this.phone = item.phone;
              this.blocked = item.blocked;
              this.blacklisted = item.blacklisted;
              this.isTwin = item.is_twin;
              this.personsList = item.escorts || [];
              if (item.department) this.department = item.department.id || 0;

              if (this.permission) {
                this.username = this.permission.username;
                this.subdivisionperm = this.filterExistSubdivisions(
                  this.permission.groupaccess
                );
                this.departmentperm = this.filterExistDepartments(
                  this.permission.departments
                );
                if (this.permission.rules) {
                  this.requestСonfirmRule =
                    this.permission.rules.request_confirm || false;
                  this.editUsersRule =
                    this.permission.rules.edit_users || false;
                  this.editDepartmentRule =
                    this.permission.rules.edit_department || false;
                  this.managerCreateRule =
                    this.permission.rules.manager_create || false;
                  this.requestEmployee =
                    this.permission.rules.request_employee || false;
                }
              }

              let self = this;
              if (item.access_groups.length) {
                this.accesslist = item.access_groups;
              }
              this.deleted = item.deleted;
              let data = {};
              if (item.data) {
                data = JSON.parse(item.data);
                this.visit_purpose = data.visit_purpose || "";
                this.document_type = data.document_type || "";
                this.document_number = data.document_number || "";
                this.note = data.note || "";
                if (this.document_number == "") {
                  this.document_type = "";
                }
                if (data.start_date) {
                  this.dateStart = this.$moment(data.start_date).format(
                    dateFormat
                  );
                  this.timeStart = this.$moment(data.start_date).format(
                    timeFormat
                  );
                }
                if (data.expire_date) {
                  this.dateExpire = this.$moment(data.expire_date).format(
                    dateFormat
                  );
                  this.timeExpire = this.$moment(data.expire_date).format(
                    timeFormat
                  );
                }
                this.post = data.post || "";
                this.onepass = data.onepass || false;
                this.tabNum = data.tab_num || "";
                this.use_personal_mode = data.use_personal_mode || false;
                this.escort_list = data.escort_list || [];
              }
            }
          })
          .catch((error) => {
            this.$router.go(-1);

            const message = error.response.data.status.message;

            switch (message) {
              case "record not found":
                this.$notify({
                  group: "info",
                  type: "error",
                  text: this.$ml.get("message.noUser"),
                });
                break;
              default:
                this.$notify({
                  group: "info",
                  type: "error",
                  text: message,
                });
                break;
            }
          });
      }
    },
    onClickSavebtn() {
      if (!this.validateForm()) return;

      if (this.faceValidErrorMessage) {
        this.$dialog
          .confirm(this.$ml.get("message.notValidateFace"), {
            loader: false,
            okText: this.$ml.get("button.save"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          })
          .then((dialog) => {
            this.storeUser();
            // dialog.close();
          });
      } else {
        this.storeUser();
      }
    },

    validateForm() {
      this.validFormMessage = "";

      let isValid = true;

      if (
        !this.last_name ||
        this.first_name.length > 40 ||
        this.last_name.length > 40 ||
        this.middle_name.length > 40
      ) {
        this.validFormMessage += `- ${this.$ml.get("user.notValidName")}<br/>`;
        isValid = false;
      }

      if (!!this.post && this.post.length > 256) {
        this.validFormMessage += `- ${this.$ml.get(
          "message.userInfoMax"
        )}<br/>`;
        isValid = false;
      }

      if (!this.department) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidDepartment"
        )}<br/>`;
        isValid = false;
      }

      if (!this.accesslist.length) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidAccess"
        )}<br/>`;
        isValid = false;
      }

      if (!this.dataProcessing) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidDataProcessing"
        )}<br/>`;
        isValid = false;
      }

      if (this.typeUser === "manager" && !this.phone) {
        this.validFormMessage += `- ${this.$ml.get("user.notValidPhone")}<br/>`;
        isValid = false;
      }

      if (
        this.typeUser === "manager" &&
        (!this.username || !this.validateEmail(this.username))
      ) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.email_char_valid"
        )}<br/>`;
        isValid = false;
      }

      if (
        this.typeUser === "manager" &&
        (!this.departmentperm || !this.departmentperm.length)
      ) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidManagerDepartment"
        )}<br/>`;
        isValid = false;
      }

      if (
        this.typeUser === "manager" &&
        (!this.subdivisionperm || !this.subdivisionperm.length)
      ) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidManagerSubdivision"
        )}<br/>`;
        isValid = false;
      }

      return isValid;
    },

    isValidPhone(p) {
      const phoneRe = /^((7)+([0-9]){10})$/gm;
      const isValid = phoneRe.test(p);

      return isValid;
    },
    storeUser() {
      if (this.phone) {
        this.phone = this.phone.replace(/\D/g, "");
        if (this.phone.substr(0, 1) == "8")
          this.phone = `7${this.phone.substr(1, 10)}`;
      }
      if (
        this.typeUser == "manager" &&
        this.password &&
        this.password !== this.password_confirmation
      ) {
        return;
      }
      if (
        this.typeUser == "manager" &&
        (!this.phone || !this.isValidPhone(this.phone))
      ) {
        this.errorMessage = this.$ml.get("user.notPhone");
        return;
      }

      if (!this.userId && !this.newphoto && !this.NoCheckPhoto) {
        this.errorMessage = this.$ml.get("user.notPhoto");
        return;
      }

      if (this.typeUser != "guest") {
        this.onepass = false;
      }
      if (this.document_number == "") {
        this.document_type = "";
      }
      this.errorMessage = "";
      this.isPendingStore = true;
      this.isSuccessStore = false;
      this.isFailureStore = false;
      this.lookBtn = true;

      const form = {
        no_check_photo: this.NoCheckPhoto,
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        type_user: this.typeUser,
        phone: this.phone,
        photo_encrypted: this.newphoto,
        photo: this.photo,
        blocked: this.blocked,
        blacklisted: this.blacklisted,
        twin_id: this.twin_id,
        cards: this.cards,
        data: {
          escort_list: this.escort_list,
          visit_purpose: this.visit_purpose,
          document_type: this.document_type,
          document_number: this.document_number,
          post: this.post,
          tab_num: this.tabNum,
          use_personal_mode: this.use_personal_mode,
          note: this.note,
        },
        access_groups: this.accesslist,
        departments_id: this.department || null,
        is_twin: this.isTwin,
      };

      if (this.typeUser == "guest") {
        form.data.onepass = this.onepass;
      }
      if (this.typeUser == "guest" || this.typeUser == "employee") {
        form.data.start_date = this.dateStart
          ? this.$moment(`${this.dateStart} ${this.timeStart}`).toISOString()
          : null;
        form.data.expire_date = this.dateExpire
          ? this.$moment(`${this.dateExpire} ${this.timeExpire}`).toISOString()
          : null;
      }

      if (this.typeUser == "manager") {
        form.permission = {
          username: this.username,
          email: this.username,
          password: this.password,
          groupaccess: this.subdivisionperm,
          departments: this.departmentperm,
          rules: {
            request_confirm: this.requestСonfirmRule,
            request_employee: this.requestEmployee,
            edit_users: this.editUsersRule,
            edit_department: this.editDepartmentRule,
            manager_create: this.managerCreateRule,
          },
        };
      }

      form.data = JSON.stringify(form.data);

      let url = "user/add";
      if (this.userId) {
        url = `user/${this.userId}`;
        form.id = this.userId;
      }
      this.axios
        .post(url, form)
        .then((response) => {
          if (response.data.status.code != 0) {
            let message = response.data.status.message || "";
            this.errorMessage = this.getLangMessage(message);
          } else {
            if (this.userId) {
              this.$router.push({ path: `/user/` + this.userId });
            } else {
              this.$router.push({ path: `/users` });
            }
          }

          this.isPendingStore = false;
          this.isSuccessStore = true;
          this.isFailureStore = false;
          this.lookBtn = false;
        })
        .catch((error) => {
          if (error.response.status == 406) {
            this.errorMessage = this.getLangMessage("Bad photo");
          } else if (error.response.status == 422) {
            this.errorMessage = this.getLangMessage(
              "Fields filled in incorrectly"
            );
          } else if (error.response.status == 503) {
            this.errorMessage = this.getLangMessage("Device Unavailable");
          } else {
            let mes = error.response.data.status.message;
            if (mes.indexOf("face match found User") !== -1 && !this.userId) {
              // Пользователь уже в базе
              let userID = parseInt(
                mes.replace("face match found User ", "").trim()
              );
              this.$dialog
                .confirm(
                  `<h3>${this.$ml.get("message.Twin")} #${userID}</h3>` +
                    this.$ml.get("message.addTwin"),
                  {
                    loader: false,
                    okText: this.$ml.get("button.save"),
                    customText: this.$ml.get("button.show"),
                    cancelText: this.$ml.get("button.cancel"),
                    backdropClose: true,
                  }
                )
                .then(() => {
                  this.twin_id = userID;
                  this.storeUser();
                });
            }
            this.errorMessage = this.getLangMessage(mes);
          }
          this.lookBtn = false;

          this.isPendingStore = false;
          this.isSuccessStore = false;
          this.isFailureStore = true;
        });
    },
    refeature() {
      let url = "/user/" + this.userId + "/refeature";
      this.axios
        .put(url)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.success == false) {
              this.errorMessage = this.getLangMessage(response.data.message);
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.refeature_success"),
              });
              //this.$router.push({ path: `/user/` + this.userId });
            }
          }
          this.lookBtn = false;
          //this.devices = response.data ? response.data : [];
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.message,
          });
          //this.errorMessage = this.getLangMessage(error.response.data.message);
        });
    },

    checkGuest() {
      let roles = this.$auth.user().roles;
      if (
        this.$auth.user().type_user != "admin" &&
        roles != null &&
        !this.userId &&
        !(roles != null && roles.includes("useredit")) &&
        !(roles != null && roles.includes("userdel"))
      ) {
        this.typeUser = "guest";
      }
    },

    getDepartmentsFlat() {
      return this.axios.get("/departments").then((response) => {
        this.departmentsFlat = response.data.data ? response.data.data : [];
      });
    },

    getSubdivisionsFlat() {
      return this.axios.get("/subdivisions").then((response) => {
        this.subdivisionsFlat = response.data.data ? response.data.data : [];
      });
    },

    filterExistDepartments(departments) {
      const existsDepartmentsId = this.departmentsFlat.map(
        (department) => department.id
      );

      return departments.filter((departmentId) =>
        existsDepartmentsId.includes(departmentId)
      );
    },

    filterExistSubdivisions(subdivisions) {
      const existsSubdivisionsId = this.subdivisionsFlat.map(
        (subdivision) => subdivision.id
      );

      return subdivisions.filter((subdivisionId) =>
        existsSubdivisionsId.includes(subdivisionId)
      );
    },

    resetStart() {
      this.dateStart = "";
      this.timeStart = "";
    },

    resetExpire() {
      this.dateExpire = "";
      this.timeExpire = "";
    },

    onChangeName({ surname, name, patronymic }) {
      this.first_name = name;
      this.middle_name = patronymic;
      this.last_name = surname;
    },

    onChangeDueDateFrom(from) {
      const to = this.dateExpire;
      const isFromAfterTo = this.$moment(from).isAfter(to);

      if (isFromAfterTo) {
        this.dateExpire = this.$moment(from).add(1, "day").format(dateFormat);
      }

      this.dateStartMenu = false;
    },

    getPersons(params) {
      this.getPersonsStatus = "IS_PENDING";

      const url = "/users";
      return this.axios
        .get(url, { params: params })
        .then((response) => {
          this.getPersonsStatus = "IS_SUCCESS";
          this.personsList = response.data.data;
        })
        .catch(() => (this.getPersonsStatus = "IS_FAILURE"));
    },

    onSearchPersons: debounce(function () {
      if (!this.searchPersons) return;
      const params = {
        search: `name:${this.searchPersons}`,
        not_escort: true,
        ignore_user_id: this.userId,
      };

      this.getPersons(params);
    }, 300),
  },

  async created() {
    this.getCompanies();
    this.getDepartments();
    this.getDevices();
    await this.getSubdivisionsFlat();
    await this.getDepartmentsFlat();
    this.getUser();
    this.checkGuest();
  },
  watch: {
    phone(value) {
      this.phone = value;
    },

    searchPersons() {
      this.onSearchPersons();
    },

    async password() {
      await this.$nextTick();
      this.$refs.passwordConfirmation.validate();
    },
  },
};
</script>
