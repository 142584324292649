export default {
  methods: {
    sliceDepartment(name) {
      let curName = name;

      if (name.length > 50 && this.$vuetify.breakpoint.mdAndUp) {
        curName = curName.slice(-50);
        curName = `...${curName}`;
      } else if (name.length > 25 && this.$vuetify.breakpoint.smAndDown) {
        curName = curName.slice(-25);
        curName = `...${curName}`;
      }

      return curName;
    },
  },
};
