<template>
  <v-app v-if="type">
    <div class="monitoring-fullscreen">
      <div class="monitoring-fullscreen__top">
        <div
          class="monitoring-fullscreen__photo"
          :style="{ backgroundImage: `url(${image})` }"
        />
        <div class="monitoring-fullscreen__info">
          <span>
            <div class="monitoring-fullscreen__meta">
              <div v-if="identityTypes.length" class="mr-2">
                <span
                  v-for="type in identityTypes"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <template v-if="type === 'face'">
                    <v-icon small class="mr-1">
                      mdi-emoticon-happy-outline
                    </v-icon>
                    <span>{{ $ml.get("users.face") }}</span>
                  </template>

                  <template v-if="type === 'qr'">
                    <v-icon small class="mr-1"> mdi-qrcode-scan </v-icon>
                    <span>{{ $ml.get("users.qr") }}</span>
                  </template>

                  <template v-if="type === 'card'">
                    <v-icon small class="mr-1">
                      mdi-card-bulleted-outline
                    </v-icon>
                    <span>{{ $ml.get("users.card") }}</span>
                  </template>
                </span>
              </div>

              <div v-if="purpose">
                <template v-if="purpose === 'entrance'">
                  <v-icon small color="success" class="mr-1"
                    >mdi-location-enter</v-icon
                  >
                  <span>{{ $ml.get("device.purpose_entrance") }}</span>
                </template>

                <template v-if="purpose === 'camera'">
                  <v-icon small color="primary" class="mirror-x mr-1"
                    >mdi-cctv</v-icon
                  >
                  <span>{{ $ml.get("device.purpose_camera") }}</span>
                </template>

                <template v-if="purpose === 'exit'">
                  <v-icon small color="error" class="rotate-180 mr-1"
                    >mdi-location-exit</v-icon
                  >
                  <span>{{ $ml.get("device.purpose_exit") }}</span>
                </template>

                <template v-if="purpose === 'basiclock'">
                  <v-icon small color="warning" class="mr-1">mdi-key</v-icon>
                </template>
              </div>
            </div>

            <div v-if="deviceName" class="monitoring-fullscreen__zone">
              {{ zone }} ({{ deviceName }})
            </div>

            <div
              v-if="temperature"
              :class="[
                'monitoring-fullscreen__temperature',
                {
                  'monitoring-fullscreen__temperature--success':
                    isNormalTemperature,
                },
                {
                  'monitoring-fullscreen__temperature--danger':
                    !isNormalTemperature,
                },
              ]"
            >
              {{ temperatureWithUnit }}
            </div>

            <strong>
              <router-link
                v-if="user_id"
                :to="{ name: 'showUser', params: { id: user_id } }"
              >
                {{ name }}
              </router-link>
              <template v-else>
                {{ name }}
              </template>
            </strong>

            <tempalte v-if="info">
              <br />
              <strong class="text-h3"> {{ info }} </strong>
            </tempalte>

            <tempalte v-if="showDepartment && department">
              <br />
              <strong class="text-h5">
                {{ sliceDepartment(department) }}
              </strong>
            </tempalte>

            <br />
            {{ createdAt | moment("DD.MM.YYYY HH:mm:ss") }}
          </span>
        </div>
      </div>
      <div
        v-if="typeText"
        :class="[
          'monitoring-fullscreen__bottom',
          {
            'monitoring-fullscreen__bottom--error': type === 'error',
          },
          {
            'monitoring-fullscreen__bottom--black': isBlacked,
          },
          {
            'monitoring-fullscreen__bottom--warning': type === 'alarm',
          },
          {
            'monitoring-fullscreen__bottom--success':
              type === 'pass' || type === 'before',
          },
        ]"
      >
        {{ typeText }}
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-if="isShowAlarmDialog"
        :value="true"
        max-width="700px"
        persistent
      >
        <ModalAlarmDialog
          :data="{
            full_name: name,
            purpose: `${zone} (${deviceName})`,
          }"
          @close="closeAlarmDialog"
        />
      </v-dialog>
    </v-row>
  </v-app>

  <v-app v-else>
    <div class="monitoring-fullscreen monitoring-fullscreen--center">
      <v-alert type="info" class="mb-0">
        {{ $ml.get("monitoring.nextEvent") }}
      </v-alert>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import ModalAlarmDialog from "./ModalAlarmDialog";

import sliceDepartment from "../../mixins/sliceDepartment";

const NEW_VISIT_EVENT = "newvisit";
const BEFORE_VISIT_EVENT = "beforevisit";
const ERROR_VISIT_EVENT = "errorvisit";
const ALARM_VISIT_EVENT = "alarmvisit";
const EVENT_VISIT_EVENT = "eventvisit";

export default {
  components: { ModalAlarmDialog },

  mixins: [sliceDepartment],

  data() {
    return {
      user_id: 0,
      name: "",
      type: "",
      isBlocked: false,
      isBlacked: false,
      image: "",
      createdAt: this.$moment().format(),
      showDepartment: false,
      department: "",
      info: "",
      identityTypes: [],
      purpose: "",
      zone: "",
      deviceName: "",
      temperature: 0.0,
      isNormalTemperature: true,

      isShowAlarmDialog: false,

      eventCounter: 0,
    };
  },

  methods: {
    onGetVisit(visit, isFirst = false, isAlarm = false) {
      const data = visit.event;

      this.eventCounter = this.eventCounter + 1;

      if (
        (!this.$auth.user().zones ||
          this.$auth.user().zones.includes(parseInt(data.zone_id))) &&
        (isFirst ||
          this.$moment(visit.created_at).isAfter(this.$moment(this.createdAt)))
      ) {
        this.user_id = 0;
        this.name =
          data.name || data.message || this.$ml.get("users.unrecognized");
        this.type = data.type || "error";
        this.isBlocked = visit.Blocked;
        this.isBlacked = visit.blacklisted;
        this.createdAt = visit.created_at;
        this.department = visit.department || "";
        this.info = visit.info || "";
        this.identityTypes = this.getIdentityTypesArr(data.identity_types);
        this.purpose = visit.purpose;
        this.zone = data.zone;
        this.deviceName = data.device_name;
        this.temperature = parseFloat(data.temperature);

        switch (data.temperature_status) {
          case "norm":
            this.isNormalTemperature = true;
            break;
          case "excess":
            this.isNormalTemperature = false;
            break;
          default:
            this.isNormalTemperature = true;
            break;
        }

        let eventType;

        if (parseInt(data.code) === 350) {
          eventType = "pressBtn";
        } else if (parseInt(data.code) >= 301) {
          eventType = "deviceAlarm";
        } else {
          eventType = "userAlarm";
        }

        if (data["image_encode"]) {
          this.image = `data:image/png;base64,${data.image_encode}`;
        } else {
          switch (eventType) {
            case "deviceAlarm":
              if (visit.photo) {
                this.image = `/avatar/profile/${visit.photo}`;
              } else {
                this.image = require("@/assets/img/error.svg");
              }
              break;
            case "pressBtn":
              this.image = require("@/assets/img/press-btn.svg");
              break;
            default:
              this.image = `/avatar/profile/${visit.photo}`;
              break;
          }
        }

        if (data.code == 304) {
          this.showAlarmDialog(visit);
        }

        this.alertNotify(visit);
      }
    },
    onGetAlarmVisit(visit) {
      this.onGetVisit(visit, false, true);
    },

    onGetNewVisit(visit) {
      const data = visit.event;

      this.eventCounter = this.eventCounter + 1;

      if (
        (!this.$auth.user().zones ||
          this.$auth.user().zones.includes(parseInt(data.zone_id))) &&
        this.$moment(visit.created_at).isAfter(this.$moment(this.createdAt))
      ) {
        this.user_id = visit.user_id;
        this.name = visit.full_name || this.$ml.get("users.unrecognized");
        this.type = "pass";
        this.createdAt = visit.created_at;
        this.image = `/avatar/profile/${visit.photo}`;
        this.identityTypes = this.getIdentityTypesArr(data.identity_types);
        this.purpose = visit.purpose;
        this.zone = data.zone;
        this.deviceName = data.device_name;

        this.alertNotify(visit);
      }
    },

    getIdentityTypesArr(types) {
      let result = [];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    connectWebSockets() {
      this.$root.$on(NEW_VISIT_EVENT, this.onGetNewVisit);
      this.$root.$on(BEFORE_VISIT_EVENT, this.onGetVisit);
      this.$root.$on(ERROR_VISIT_EVENT, this.onGetVisit);
      this.$root.$on(ALARM_VISIT_EVENT, this.onGetAlarmVisit);
      this.$root.$on(EVENT_VISIT_EVENT, this.onGetVisit);
    },

    disconnectedWebSockets() {
      this.$root.$off(NEW_VISIT_EVENT);
      this.$root.$off(BEFORE_VISIT_EVENT);
      this.$root.$off(ERROR_VISIT_EVENT);
      this.$root.$off(ALARM_VISIT_EVENT);
      this.$root.$off(EVENT_VISIT_EVENT);
    },

    getEventLog() {
      const params = {
        limit: 1,
      };

      this.axios("alarmvisits", { params }).then((response) => {
        const visits = response.data.data;

        if (visits.length) {
          this.onGetVisit(visits[0], true);
        }
      });
    },

    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
    },

    showAlarmDialog() {
      this.isShowAlarmDialog = true;
    },

    alertNotify(visit) {
      if (visit.Blocked) {
        this.$notify({
          type: "error",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassBlockUser"),
          duration: -1,
        });
      }

      if (visit.blacklisted) {
        this.$notify({
          type: "warn",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassBlacklistUser"),
          duration: -1,
        });
      }

      if (visit.event.temperature_status === "excess") {
        this.$notify({
          type: "error",
          group: "info",
          title: visit.full_name,
          text: this.$ml.get("users.attemptPassTemperatureUser"),
          duration: -1,
        });
      }
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),

    typeText() {
      switch (this.type) {
        case "pass":
          return this.$ml.get("users.recognizedAndPass");
        case "before":
          return this.$ml.get("users.recognized");
        case "error":
          if (this.isBlocked) {
            return this.$ml.get("users.blocked");
          } else if (this.isBlacked) {
            return this.$ml.get("users.blacklist");
          } else {
            return this.$ml.get("users.error");
          }
        case "alarm":
        case "event":
          if (this.purpose != "basiclock") {
            return this.$ml.get("users.notRecognized");
          } else {
            return "";
          }
        default:
          return "";
      }
    },

    temperatureWithUnit() {
      return `${this.temperature} °${this.settings.temp_meas.toUpperCase()}`;
    },
  },

  destroyed() {
    this.disconnectedWebSockets();
  },

  created() {
    this.showDepartment = localStorage.getItem("showDepartment") == "true";
    this.connectWebSockets();
    this.getEventLog();
  },
};
</script>
